/* body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
} */

/* .payment-slip {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 400px;
  padding: 40px;
  border-radius: 10px;
  background-color: #f3f3f3;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  color: #333;
  position: relative;
  overflow: hidden;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.header h2 {
  font-size: 28px;
  margin: 0;
  color: #007bff;
}

.transaction-id {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
  color: #888;
}

.details p {
  margin: 5px 0;
  font-size: 14px;
  line-height: 1.6;
}

.details strong {
  font-weight: bold;
}

.total-amount p {
  margin: 5px 0;
  font-size: 24px;
  font-weight: bold;
  text-align: right;
  color: #2a9d8f;
}

.footer {
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
  color: #888;
}

.paytm-logo {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 60px;
}

.button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.2s ease;
}

.button:hover {
  background-color: #0056b3;
} */

.payment-slip {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 400px;
  padding: 40px;
  border-radius: 10px;
  /* background: linear-gradient(120deg, #667eea, #764ba2); */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  color: #000000;
  position: relative;
  overflow: hidden;
}
.payment-slip:before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  /* background: rgba(255, 255, 255, 0.1); */
  transform: skewX(-45deg);
}
.transaction-id {
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
}
.header {
  text-align: center;
  margin-bottom: 30px;
}
.header h2 {
  font-size: 28px;
  margin: 0;
}
.details p {
  margin: 5px 0;
  font-size: 14px;
  line-height: 1.6;
}
.details strong {
  font-weight: bold;
}
.total-amount p {
  margin: 5px 0;
  font-size: 24px;
  font-weight: bold;
  text-align: right;
}
